<template>
  <div>
    <div v-if="item">
        <span
          :class="
            item.kpiCategory == 'OVERDUE'
              ? 'overdue'
              : item.kpiCategory == 'DANGER'
              ? 'inDanger'
              : item.kpiCategory == 'GOOD'
              ? 'withinKPI'
              : ''
          " style="font-size: 11px"
          >{{ item.document }}</span
        >
    </div>
    <div v-else>
        {{ group }}
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    item: {},
    group: null,
  }),
  mounted() {
    this.item = this.params.data;
    if (this.params.node && this.params.node.group && this.params.node.key) {
      this.group = this.params.value;
    }
  },
  methods: {},
};
</script>
<style scoped>
.rejected {
  color: var(--v-danger-base) !important;
}
.overdue {
  color: var(--v-redPop-lighten1) !important;
}
.inDanger {
  color: #dfaa49 !important;
}
.withinKPI {
  color: var(--v-tertiary-base) !important;
}
</style>