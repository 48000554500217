var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.item)?_c('div',[_c('span',{class:_vm.item.kpiCategory == 'OVERDUE'
            ? 'overdue'
            : _vm.item.kpiCategory == 'DANGER'
            ? 'inDanger'
            : _vm.item.kpiCategory == 'GOOD'
            ? 'withinKPI'
            : '',staticStyle:{"font-size":"11px"}},[_vm._v(_vm._s(_vm.item.document))])]):_c('div',[_vm._v(" "+_vm._s(_vm.group)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }